form {
  p {
    color: $secondary-color;
  }

  p strong {
    color: $primary-color;
  }

  label {
    font-size: 12px;
    text-transform: uppercase;
    color: #848484;
  }

  input:not([type=radio]), .form-group textarea:focus {
    border: none;
    width: 100%;
  }

  input:not([type=radio]):focus, input[type='text']:focus, .form-group textarea:focus {
    -webkit-box-shadow: -5px 5px 8px 0px rgba(246, 186, 30, .6);
    -moz-box-shadow: -5px 5px 8px 0px rgba(246, 186, 30, .6);
    box-shadow: -5px 5px 8px 0px rgba(246, 186, 30, .6);

  }

  .form-control:focus {
    border-color: $primary-color;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(246, 186, 30, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(246, 186, 30, .6);
  }


  .chosen-container-active .chosen-choices {
    border-color: $primary-color;
  }

  .chosen-container .chosen-drop {
    border-color: $primary-color;
    -webkit-box-shadow: 0 8px 8px rgba(246, 186, 30, .25);
    -moz-box-shadow: 0 8px 8px rgba(246, 186, 30, .25);
    box-shadow: 0 8px 8px rgba(246, 186, 30, .25);
  }

  input[type='text'], input[type='email'], .form-group textarea {
    color: $primary-color;
  }

  input[type="checkbox"] {
    width: auto;
    position: relative !important;
  }

  input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
    color: $white;
    border-color: $primary-color;
    background-color: $primary-color;
  }

  .input-group-addon {
    border-width: 1px !important;
  }

  .datepicker > div.row {
    display: block;
  }

  .gamme-label {
    padding-left: 5px;
    font-weight: bold;
    text-transform: none;
  }
  
  h2 {
    text-align: center;
  }

  h3 {
    color: $primary-color;
    margin: 50px 0 5px;

    @media (max-width: $screen-xs-max) {
      margin-top: 30px;
    }
  }

  hr {
    border-top: 1px solid $primary-color;
    opacity: 0.5;
  }

  #feedback-notes .select-radio {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  input:not([type=radio]) {
    border-bottom: none!important;
  }
}

form.has-global-errors {
  .help-block {
    color: #a94442;
  }
}

.select-radio, .select-checkbox {
  text-align: center;

  input {
    display: none;
  }

  label {
    color: $grey;
    padding: 10px 12px;
    margin: 10px 10px;
    border: 2px solid $grey;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    @media (max-width: $screen-xs-max) {
      display: block;
      width: 90%;
    }
  }

  .half-top-circle{
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    height: 32px;
    width: 65px;
    padding-top: 0;
    padding-bottom: 0;
    //margin-bottom: 0;
    margin-bottom: -7px;

    padding-left: 0;
    padding-right: 0;

    margin-left: 5px;
    margin-right: 5px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: -2px;
    }
  }
  .half-bottom-circle{
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    height: 32px;
    width: 65px;
    padding-top: 4px;
    //margin-top: 0;
    margin-top: -5px;

    padding-left: 0;
    padding-right: 0;

    margin-left: 5px;
    margin-right: 5px;
  }
}

.table{
  th {
    text-align: center;
    color: $white;
    background-color: $primary-color;
    border-top: none!important; //remove trait gris central
  }
  .select-checkbox td:first-child{
    color: $primary-color;
    font-weight: bold;
    padding-top: 10px;
  }
}

.table-share{
  th {
    background-color: lighten($share-color, 50%);
  }
}

.check-morning, .check-afternoon, .check-all {
  cursor: pointer;
}

@media (max-width: $screen-xs-max) {
  .table .select-checkbox td {
    padding-top: 0 !important;
  }

  .table .half-top-circle .unselectable, .table .half-bottom-circle .unselectable {
    width: 65px;
    margin: auto;
    padding-top: 4px;
  }

  .select-checkbox .half-top-circle {
    padding-top: 7px;
  }
}

.glyphicon-calendar:before {
  color: #ffffff;
  background-color: #f6ba29;
}

.input-group-addon:first-child {
  background-color: #f6ba29;
}

.form-help {
  font-size: 0.8em;
  color: #848484;
}

div.intl-tel-input {
  display: block;
}