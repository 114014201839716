/* ESPACE WORKER */
#my-profil {
	p {
		@include factotxt($secondary-color, bold, none, 12px);
		margin: 5px 0 0 15px;
		span {
			color: $grey;
		}
	}
}

#subheader {
	height: 70px;
	padding-top: 10px;
	background-color: $primary-color;

	p {
		color: $white;
		margin: 15px 0 10px 0;
	}

	@media(max-width: 480px) {
		text-align: center;
		height: 120px;
	}

	button {
		outline: none;
		float: right;
		@media(max-width: $screen-xs-max) {
			float: none;
		}
	}
}

.hr {
	clear: both;
	margin: 0 0;
}

/* WORKER PROFIL */
#working {
	.working-map {
		width: 100%;
		height: 280px;
	}
}

#dashboard {
	margin-top: 30px;

	li a {
		@include factotxt($grey, bold, uppercase);
	}

	.nav-tabs>li.active>a,.nav-tabs>li.active>a:hover,.nav-tabs>li.active>a:focus {
		border-bottom: 8px solid $primary-color;
		color: $primary-color;
		background-color: transparent;
	}

	#list-mission {
		.border-dotted {
			height: 100%;
			padding: 10px 5px;
			margin: 0 20px;
		}
	}
}

#dash-select {
	select {
		@include factotxt($primary-color, bold, uppercase);
	}
}

.mission {
	.border-custom {
		width: 160px;
		position: relative;
		top: -25px;
		left: -40px;
		background-color: $primary-color;
		border-radius: 10px 10px 40px 5px;
		padding: 5px 15px;
		@include factotxt($white, bold, none, 12px);
		text-transform: uppercase;
		text-align: center;
	}

	h3 {
		margin: 0 0;
	}

	p {
		margin-bottom: 0;
	}

	.row .row {
		margin-bottom: 20px;
	}

	.fa-arrow-right {
		color: $primary-color;
	}

	.end {
		background-color: $grey;
		color: grey;
	}

	.span-mission {
		@include factotxt($grey, bold, uppercase, 12px);
		margin: 5px 0;
		margin-top: 20px;
	}
}

.role {
	@include factotxt($secondary-color, normal, capitalize, 18px);
	margin-bottom: 0;
}

.brand-name {
	@include factotxt($secondary-color, 900, uppercase, 10px);
	margin: 0 0;
}

aside {
	h2 {
		margin-top: 0;
	}

	p {
		margin-bottom: 0;
	}

	.badge {
		background-color: $primary-color;
	}

	#total {
		span {
			@include factotxt(primary-color, bold, 14px);
		}
	}
}

/* MODAL */
.icon-modal {
	margin-left: -40px;
}

#eval {
	h2 {
		margin-bottom: 30px;
	}

	.rating {
		overflow: hidden;
		display: inline-block;
		.rating-input {
			float: right;
			width: 64px;
			height: 64px;
			padding: 0;
			margin: 0 0 0 -32px;
			opacity: 0;
		}

		.rating-star,.rating:hover .rating-star {
			position: relative;
			float: right;
			display: block;
			width: 64px;
			height: 64px;
			background: url('../../img/iconstar.png');
			background-position: bottom;
		}

		.rating-star:hover, .rating-star:hover ~ .rating-star,.rating-input:checked ~ .rating-star {
			background-position: 0 0;
			background-color: $white;
		}
	}
}

.dispo {
	background-color: $primary-color;

	.select-checkbox {
		display: inline-flex;
		margin-top: 10px;
		@media (max-width: 480px) {
			display: block;
		}

		input {
			display: none;
			margin:0 0;
		}

		label {
			margin: 10px 10px;
			border: 2px solid $white;
			display: inline-block;
			width: 50px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			cursor: pointer;
			border-radius: 25px;
			background-color: rgba(255, 255, 255, 0.1);
		}
	}

	h2 {
		color: $white;
		margin: 20px 0;
	}

	form label {
		color: $white;
	}

	input[type="checkbox"]:checked + label,input[type="radio"]:checked + label {
		background-color: white;
		color: $primary-color;
	}

	button[type="submit"] {
		background-color: rgba(255, 255, 255, 0.1);
	}

	button[type="submit"]:hover {
		background-color: white;
		color: $primary-color;
	}

	#week-end {
		width: 150px;
		@media (max-width: 480px) {
			text-align: center;
		}
	}
}
