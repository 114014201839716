@mixin factotxt($color: inherit, $weight: normal, $transform: none, $size: inherite) {
    font: {
        family: $main-font;
        size: $size;
        weight: $weight;
    }
    color: $color;
    text-transform: $transform;
}

/*@include factotxt($color, $weight, $transform, $size);*/


.bg-color-primary {
  background-color: $primary-color !important;
}

/*COMMUN*/
h1 {
    @include factotxt($white, normal);
}

h2 {
    @media (max-width: $screen-xs-max) {
        text-align: center;
    }
}

h2, h3, p {
    @include factotxt($blue, normal);
}

hn {
    color: $grey;
}


.container-fluid {
    padding: 0 15px;

    @media (max-width: $screen-xs-max) {
        padding: 0;
    }
}

.text-default {
    color: $blue;
}

.text-reverse {
    color: $white;
}

.text-blue{
    color: $blue;
}

.text-yellow{
    color: $yellow;
}

.text-white {
    color: $white;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.extra-big-font {
    font-size: 5.7em;
    margin-bottom: 0;
}

.btn {
    @include factotxt($white, bolder, uppercase, 14px);
    border: none;

    padding: 13px 30px;

    button {
        padding: 0 20px;
        margin: 0 auto 20px auto;
        height: 50px;
    }

    a {
        text-decoration: none;
    }

    &:hover {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-sm {
    font-size: 0.8em;
    padding: 8px;
}

.btn-xs {
    font-size: 0.6em;
    padding: 6px;
}

.btn-primary {
    background-color: $primary-color;
    &:hover {
        color: $primary-color;
        background-color: $white;
    }
}

.btn-reverse {
    background-color: $secondary-color;

    &:hover {
        color: $secondary-color;
        background-color: $white;
    }
}

.btn-transparent {
    color: $white;
    text-align: center;
    //border: 2px solid white;
    background-color: transparent;

    &:hover {
        color: $primary-color;
        border-color: $primary-color;
    }

    a {
        color: $white;
    }
}

.button-retour {
    margin: 10px auto;
    background-color: transparent;
    border: none;

    a {
        color: $grey;
    }

    &:hover {
        color: $primary-color;
        text-decoration: none;
    }
}

.thumb-avatar {
    margin-top: 5px;
    height: 40px;
    width: 40px;
}

.thumb-brand {
    height: auto;
    width: 100px;
}

.cadre {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
}

.quote {
    margin-top: 20px;
    background: url("../../img/quote.png") no-repeat;

    img {
        margin-top: 10px;
    }

    p {
        padding-left: 40px;
    }
}

.bg-white {
    background-color: $white;
}

.bg-blue {
    width: 100%;
    background-color: $blue;

    h2, p, .modal-header, .close {
        color: $white;
    }
}

#bg-error {
    width:100%;
    height: 715px;

    @media (min-width: 1025px) {
        background-size: cover;
        height: 900px;
    }
}

#error-logo {
    margin-top: 22%;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.border-dotted {
    border: 2px dotted $grey;
    padding: 56px 100px;
    margin-bottom: 30px;
}

.arrow {
    background-image: url("../../img/right.png");
}

.vertical-col {
    display: table;
}

.vertical-align {
    float: none;
    display: table-cell;
    //vertical-align: bottom;
    //vertical-align: top;
    vertical-align: middle;
    @media (max-width: $screen-xs-max) {
        display: block;
        float: initial;
    }
}

.vertical-align-center {
    @extend .vertical-align;
    vertical-align: middle;
}

.nowrap {
    white-space: nowrap;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-left-30 {
    margin-left: 30px;
}

.no-padding {
    padding: 0 !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.clickable {
    cursor: pointer;
}

.modal-header {
    font-size: 1.2em;
}

.modal {
    z-index: 10000;
}

.modal-dialog {
    margin-top: 80px;
}

.text-cnil {
    p {
        font-size: 0.7em;
        color: #777;
        text-align: justify;
    }

    #header-manager {
        padding-bottom: 15%;
    }
    font-size: 0.7em;
    color: #777;
    text-align: justify;
}

.text-asterisk {
    @extend .text-cnil;
}

.worker-photo {
    max-height: 250px;
    margin-left: auto;
    margin-right: auto;
}

.brand-logo {
    max-height: 130px;
    margin-left: auto;
    margin-right: auto;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* works on firefox and safari */
img {
    image-orientation: from-image;
}

/* ######        complete BOOTSTRAP        ###### */
/**************************************************/
.form-group.required .control-label:after, .form-group .control-label.required:after {
    content: "*";
    color: red;
}


/* FORM */
@import "form";

/* DATATABLES */
@import 'datatables';

/* HOMEPAGE WORKER + MANAGER */
/*COMMUN */
nav {
    .navbar {
        border-top: 0;
        z-index: 10000;
    }

    a:link {
        @include factotxt($white, bold, none, 14px);
        text-transform: uppercase;
        text-decoration: none;
        line-height: 20px;
    }

    .navbar-nav {
        li > .btn {
            margin: 0 7px;

            &:hover {
                color: $primary-color;
                border-color: $white;
                background-color: $white;
            }
            @media (max-width: $screen-sm) {
                margin: 10px 14px;
            }
        }

        .open {
            a {
                color: $white;
                &:focus{
                    color: $white !important;
                }
            }

        }
    }

    .navbar-brand {
        transition: top 3s ease-out;
        .img-responsive {
            max-width: 100%;
            max-height: 40px;
            filter: hue-rotate(340deg) brightness(100);
        }
    }

    .dropdown-menu {
        border-top: 0;
        background-color: $primary-color;
        .navbar-link:hover, li a:hover {
            background-color: $primary-color !important;
            color: $secondary-color !important;
        }

        li > a {
            color: $white;
            padding: 10px 20px 5px 20px;
        }
    }
}

nav.navbar-home {
    .navbar-nav li > .btn, .navbar-nav a.dropdown-toggle {
        margin: 5px 7px;
    }
    .navbar-nav li > .btn-reverse {
        height: 50px;
        margin: 0 7px;
    }
}

.navbar-margin {
    .nav-header-collapse {
        margin-top: 20px;
    }
    .navbar-brand {
        transition: top 3s ease-out;
        .img-responsive {
            margin-top: 0;
            @media (max-width: $screen-xs-max) {
                margin-top: 0;
            }
        }
    }
}

.navbar-home {
    border-top: 0;
    background-color: $primary-color;
    height: 50px;
    z-index: 10000;

    .nav-header-collapse {
        margin-top: 0;
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
            background-color: $primary-color !important;
        }
    }

    .navbar-brand {
        @media (min-width: $screen-sm-min) {
            padding-top: 5px !important;
        }

        .img-responsive {
            margin-top: 0;
            filter: none;
        }
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
    }

    .btn {
        height: 40px;
        padding: 15px 10px;
    }

    a:hover {
        color: $primary-color !important;
    }

    a.dropdown-toggle:hover {
        color: $secondary-color !important;
    }
}

.navbar-collapse {
    @media (max-width: $screen-xs-max) {
        background-color: $primary-color !important;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.scrollToTop {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    cursor: pointer; /* Add a mouse pointer on hover */

    padding: 13px 18px !important;
    .fa-angle-double-up {
        font-size: 30px;
    }
}

footer {
    hr {
        margin: 20px 0;
        border: 1px solid $white;
    }

    #info {
        i, p, a, li {
            color: $white;
            margin: 0 auto;
        }

        a:hover {
            color: $yellow !important;
        }

        li {
            margin: 2% 0 4% 0;
            padding-left: 2px;
            padding-right: 2px;
        }

        .label {
            text-transform: uppercase;
        }

        .row {
            margin-bottom: 10px;
        }

        #contact .row {
            padding-left: 20px;
            margin: auto;
        }
    }

    #social {
        text-align: center;
        p {
            margin: 20px;
        }

        ul {
            margin-bottom: 0;
        }

        .welcome-logo {
            margin-bottom: 15px;
            height: 70px;
        }
    }
}


/* ESPACE WORKER + MODAL */
@import 'worker_profil';
